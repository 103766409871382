import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["cardElement", "cardErrors", "form"];

  connect() {
    var stripe = Stripe(this.data.get("key"));
    var elements = stripe.elements();
    var style = {
      base: {
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        "::placeholder": {
          color: "#718096"
        },
        iconColor: "#a0aec0"
      },
      invalid: {
        color: "#e53e3e",
        iconColor: "#e53e3e"
      }
    };

    var card = elements.create("card", {
      hidePostalCode: true,
      style: style
    });

    card.mount(this.cardElementTarget);

    let controller = this;
    card.addEventListener("change", function(event) {
      var displayError = controller.cardErrorsTarget;
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = "";
      }
    });

    var form = controller.formTarget;
    var setupIntentId = controller.data.get("setup-intent");

    form.addEventListener("submit", function(event) {
      event.preventDefault();

      if (setupIntentId) {
        stripe.confirmCardSetup(setupIntentId, {
          payment_method: {
            type: 'card',
            card: card
          }
        }).then(function(result) {
          if (result.error) {
            var errorElement = controller.cardErrorsTarget;
            errorElement.textContent = result.error.message;
          } else {
            controller.stripePaymentMethodHandler(result);
          }
        });
      } else {
        stripe.createPaymentMethod({
          type: 'card',
          card: card
        }).then(function(result) {
          if (result.error) {
            var errorElement = controller.cardErrorsTarget;
            errorElement.textContent = result.error.message;
          } else {
            controller.stripePaymentMethodHandler(result);
          }
        });
      }
    });
  }

  stripePaymentMethodHandler(result) {
    var form = this.formTarget;
    var hiddenInput = document.createElement("input");
    hiddenInput.setAttribute("type", "hidden");
    hiddenInput.setAttribute("name", "payment_method");
    hiddenInput.setAttribute("value", result.paymentMethod.id);
    form.appendChild(hiddenInput);

    form.submit();
  }
}
