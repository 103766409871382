import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["spinner", "menu"];

  connect() {
    this.load();

    if (this.data.has("refreshInterval")) {
      this.startRefreshing();
    }
  }

  disconnect() {
    this.stopRefreshing();
  }

  load() {
    fetch(this.data.get("url"))
      .then(response => response.json())
      .then(data => {
        if (data.cached) {
          this.spinnerTarget.classList.add("hidden");
          this.menuTarget.classList.remove("hidden");
          this.stopRefreshing();
        }
      });
  }

  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      this.load();
    }, this.data.get("refreshInterval"));
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }
  }
}
