import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "span" ]

  copy() {
    var temp = document.createElement("input");
    temp.type = "text";
    temp.value = this.data.get("url");
    document.body.appendChild(temp);
    temp.select();
    document.execCommand('copy');
    document.body.removeChild(temp);

    this.spanTarget.textContent = "Copied"

    setTimeout(() => this.spanTarget.textContent = "Copy iCal link", 1000)
  };
}
